import InvestBanner from "../Invest/InvestBanner";

const Docs = () => {
  return (
    <div style={{padding: 32}}>
      <InvestBanner />
    </div>
  );
};

export default Docs;
